import { Layout, ProjectCard, SEO } from "../components"
import { Link, withI18next } from "gatsby-plugin-i18next"

import Breadcrumb from "react-bootstrap/Breadcrumb"
import { I18n } from "react-i18next"
import React from "react"
import { graphql } from "gatsby"

const Projects = ({ data }) => (
  <I18n>
    {t => (
      <Layout>
        <SEO title={t("tusluud")} />
        <Breadcrumb>
          <li className="breadcrumb-item">
            <Link to="/">{t("home")}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/projects">{t("tusluud")}</Link>
          </li>
        </Breadcrumb>
        <h4>{t("niit_tosluud")}</h4>
        <ul>
          {data.allContentfulProject.edges.map(project => (
            <ProjectCard {...project.node} key={project.node.id} />
          ))}
        </ul>
      </Layout>
    )}
  </I18n>
)
export default withI18next()(Projects)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
    allContentfulProject(
      filter: { node_locale: { eq: $lng } }
      sort: { fields: endDate, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          status
        }
      }
    }
  }
`
